import React, { useEffect } from "react";
import Layout from '../components/Layout'
import '../styles/fonts.css'
import '../styles/app.scss'
import NextNProgress from "nextjs-progressbar";

import { init } from "@socialgouv/matomo-next";

export function reportWebVitals(metric) {
  // console.log(metric)
}

const MATOMO_URL = process.env.MATOMO_URL;
const MATOMO_SITE_ID = process.env.MATOMO_SITE_ID;

export default function MyApp({ Component, pageProps }) {

  useEffect(() => {
    init({ url: MATOMO_URL, siteId: MATOMO_SITE_ID });
  }, []);
  
  return (
    <Layout>
      <NextNProgress  height={4} color="#09c2f1" startPosition={0.1} options={{ showSpinner: false }} />
      <Component {...pageProps} />
    </Layout>
  )
}

